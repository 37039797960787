import 'javascripts/all_theme_styles.js'
import 'javascripts/all_pages.js'
import { comparisonWidget } from 'javascripts/product_comparisons/comparison_widget'

/* stylesheet */
import 'stylesheets/pages2/home.scss'

// Removing home search field animation on load
// window.addEventListener('load', function () {
//   $('.home-search, .search-submit .submit-form').addClass('transition-trigger');
//   setTimeout(function() {
//     $('.home-search input').addClass('transition-trigger');
//   }, 500);
// });

// $('.home-search input').click(function () {
//   $(this).removeClass('transition-trigger').css('width', '90%');
// });

$('.home-search input').focus();
comparisonWidget();


